import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FilledInput,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import DataTableItem from "react-data-table-component";
import localizationStrings from "../../utils/localizations";
import { baseUrl } from "../../utils/constants";

export default function Notification() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [titleEn, setTitleEn] = useState("");
  const [bodyEn, setBodyEn] = useState("");
  const [titleAr, setTitleAr] = useState("");
  const [bodyAr, setBodyAr] = useState("");
  const [seperateEnAr, setSeperateEnAr] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const handleClose = () => {
    setTitleEn("");
    setBodyEn("");
    setTitleAr("");
    setBodyAr("");
    setModalVisible(false);
  };

  const getNotifications = async (silent = false) => {
    if (!silent) setLoading(true);
    try {
      const data = await axios.get(baseUrl + "notification");
      if (data.data.success) {
        setData(data.data.data);
        setFilteredData(data.data.data);
      }
      if (!silent) setLoading(false);
    } catch (e) {
      if (!silent) setLoading(false);
      console.warn(e);
    }
  };
  useEffect(() => {
    getNotifications();
    const int = setInterval(() => {
      getNotifications(true);
    }, 60 * 1000);
    return () => clearInterval(int);
  }, []);

  const pressSave = async () => {
    setModalLoading(true);
    try {
      const bdy = {
        title_en: titleEn,
        body_en: bodyEn,
        title_ar: seperateEnAr ? titleAr : titleEn,
        body_ar: seperateEnAr ? bodyAr : bodyEn,
      };
      // if (currentSelected) bdy["id"] = currentSelected.id;
      await axios.post(baseUrl + "notification", bdy);
      await getNotifications(true);
      setModalLoading(false);
      handleClose();
    } catch (e) {
      setModalLoading(false);
      console.warn(e);
    }
  };

  if (loading) {
    return (
      <div className="tracking-container" style={{ alignItems: "center", justifyContent: "center" }}>
        <div className="loader"></div>
      </div>
    );
  }
  const handleSearch = () => {
    setFilteredData(data.filter((v) => JSON.stringify(v).toLocaleLowerCase().includes(search.toLocaleLowerCase())));
  };

  return (
    <div style={{ height: "calc(100vh - 60px)", width: "100%", padding: 30 }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", mb: "15px" }}>
          {/* <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} onClick={() => setModalVisible(true)}> */}
          <Button variant="contained" onClick={() => setModalVisible(true)}>
            {localizationStrings.Add}
          </Button>
          {/* </Box> */}
          <div style={{ flex: 1, display: "flex" }} />
          <Input
            style={{ backgroundColor: "#fff" }}
            placeholder={localizationStrings.Search}
            color="primary"
            value={search}
            onChange={(e) => setSearch(e.nativeEvent.target.value)}
            endAdornment={
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <IconButton
                  onClick={() => {
                    setFilteredData(data);
                    setSearch("");
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton type="submit" onClick={handleSearch}>
                  <SearchIcon />
                </IconButton>
              </Box>
            }
          />
        </Box>
      </form>
      {data.length === 0 ? (
        <div className="tracking-container" style={{ alignItems: "center", justifyContent: "center" }}>
          <h3 style={{ textAlign: "center" }}>{localizationStrings.NoNotification}</h3>
        </div>
      ) : (
        <DataTableItem
          data={filteredData}
          columns={[
            {
              selector: (row) => row.title_ar,
              name: <div>Title (Arabic)</div>,
              sortable: true,
              grow: 1,
              wrap: true,
            },
            {
              selector: (row) => row.title_en,
              name: <div>Title (English)</div>,
              sortable: true,
              grow: 1,
              wrap: true,
            },
            {
              selector: (row) => (
                <div>
                  <br />
                  {row.body_ar.split("\n").map((v) => (
                    <div>
                      {v}
                      <br />
                    </div>
                  ))}
                  <br />
                </div>
              ),
              name: <div>Body (Arabic)</div>,
              sortable: true,
              grow: 2,
              wrap: true,
            },
            {
              selector: (row) => (
                <div>
                  <br />
                  {row.body_en.split("\n").map((v) => (
                    <div>
                      {v}
                      <br />
                    </div>
                  ))}
                  <br />
                </div>
              ),
              name: <div>Body (English)</div>,
              sortable: true,
              grow: 2,
              wrap: true,
            },
            {
              selector: (row) => moment(row.created_at).format("DD/MM/YYYY HH:mm:ss"),
              name: <div>{localizationStrings.CreatedAt}</div>,
              sortable: true,
              sortFunction: (a, b) => moment(a).unix() - moment(b).unix(),
              grow: 1,
              wrap: true,
            },
            {
              selector: (row) => (
                <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "column", md: "column", lg: "row" } }}>
                  {/* <Button variant='outlined' startIcon={<VisibilityIcon />}>
                  View
                </Button>
                <div style={{ width: 10, height: 10 }} /> */}
                  <Button
                    style={{ color: "black" }}
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete!")) {
                        setLoading(true);
                        axios
                          .delete(baseUrl + "notification/" + row._id)
                          .then((rslt) => {
                            if (!rslt.data.success) {
                              alert(rslt.message || "Something went wrong");
                            }
                            getNotifications();
                          })
                          .catch((err) => {
                            alert(err.response?.data?.message || err.message || "Error: Unable to delete");
                            setLoading(false);
                          });
                      }
                    }}
                  >
                    {localizationStrings.Delete}
                  </Button>
                </Box>
              ),
              name: <div>{localizationStrings.Options || "Options"}</div>,
              sortable: true,
              grow: 2,
              wrap: true,
            },
          ]}
          highlightOnHover
          fixedHeader
          pagination
          responsive
          style={{ height: "calc(100vh - 82px)" }}
          customStyles={{
            headCells: { style: { backgroundColor: "#4A4B6A", color: "#000", fontSize: "11px" } },
            responsiveWrapper: { style: { height: "calc(100vh - 220px)" } },
            tableWrapper: { style: { width: "max(1000px, 100%)" } },
            cells: { style: { fontSize: "11px" } },
          }}
          paginationComponent={({ rowsPerPage, rowCount, currentPage, onChangePage, onChangeRowsPerPage }) => (
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end", minHeight: 56 }}>
              <Typography sx={{ margin: "0 24px", color: "rgba(0, 0, 0, 0.54)", fontSize: "13px" }}>
                {(currentPage - 1) * rowsPerPage + 1}-{currentPage * rowsPerPage > rowCount ? rowCount : currentPage * rowsPerPage} of {rowCount}
              </Typography>
              <div style={{ display: "flex", alignItems: "center" }}>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === 1}
                  style={{ fill: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})`, color: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})` }}
                  onClick={() => onChangePage(1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                    <path fill="none" d="M24 24H0V0h24v24z"></path>
                  </svg>
                </button>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === 1}
                  style={{ fill: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})`, color: `rgba(0,0,0,${currentPage === 1 ? "0.18" : "0.54"})` }}
                  onClick={() => onChangePage(currentPage - 1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === Math.ceil(rowCount / rowsPerPage)}
                  style={{
                    fill: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                    color: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                  }}
                  onClick={() => onChangePage(currentPage + 1)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                </button>
                <button
                  className="changeButton"
                  type="button"
                  disabled={currentPage === Math.ceil(rowCount / rowsPerPage)}
                  style={{
                    fill: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                    color: `rgba(0,0,0,${currentPage === Math.ceil(rowCount / rowsPerPage) ? "0.18" : "0.54"})`,
                  }}
                  onClick={() => onChangePage(Math.ceil(rowCount / rowsPerPage))}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                    <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                    <path fill="none" d="M0 0h24v24H0V0z"></path>
                  </svg>
                </button>
              </div>
            </Box>
          )}
        />
      )}
      {modalVisible && (
        <div className="modal-container">
          <div aria-hidden="true" className="modal-back" onClick={handleClose}></div>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: { xs: "90%", sm: "70%", md: "60%", lg: "40%", xl: "30%" },
              bgcolor: "background.paper",
              borderRadius: 1,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ position: "absolute", top: 20, [localizationStrings.getLanguage() === "ar" ? "left" : "right"]: 20 }}>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Typography id="modal-modal-description" sx={{ mt: 5, textAlign: "center", fontWeight: "700", fontSize: 18 }}>
              {localizationStrings.Add}
            </Typography>
            <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
              <div style={{ height: 20 }} />
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Checkbox value={seperateEnAr} onChange={(e) => setSeperateEnAr(e.target.checked)} />
                <Typography>Separate Arabic and English?</Typography>
              </div>
              <div style={{ height: 20 }} />
              <TextField
                label={seperateEnAr ? "Title (English)" : "Title"}
                variant="filled"
                value={titleEn}
                onChange={(e) => setTitleEn(e.target.value)}
              />
              <div style={{ height: 20 }} />
              <TextField
                label={seperateEnAr ? "Body (English)" : "Body"}
                multiline
                minRows={3}
                variant="filled"
                value={bodyEn}
                onChange={(e) => setBodyEn(e.target.value)}
              />
              <div style={{ height: 20 }} />
              {seperateEnAr && <TextField label={"Title (Arabic)"} variant="filled" value={titleAr} onChange={(e) => setTitleAr(e.target.value)} />}
              {seperateEnAr && <div style={{ height: 20 }} />}
              {seperateEnAr && (
                <TextField
                  label={"Body (Arabic)"}
                  multiline
                  minRows={3}
                  variant="filled"
                  value={bodyAr}
                  onChange={(e) => setBodyAr(e.target.value)}
                />
              )}
              {seperateEnAr && <div style={{ height: 20 }} />}
              {modalLoading ? (
                <div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
                  <div className="loader" />
                </div>
              ) : (
                <Button onClick={() => pressSave()} variant="contained">
                  {localizationStrings.Save}
                </Button>
              )}
              <div style={{ height: 20 }} />
            </Box>
          </Box>
        </div>
      )}
    </div>
  );
}
