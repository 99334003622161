import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, IconButton, Input, TextField, Typography } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataTableItem from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../utils/constants";
import localizationStrings from "../utils/localizations";

// const columns = ;

export default function TranslationsEditor() {
  const [, setData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [translations, setTranslations] = useState("");

  const [, reload] = useState({});

  const getTranslations = async (silent = false) => {
    if (!silent) setLoading(true);
    try {
      const data = await axios.get(baseUrl + "translations");
      if (data.data.success) {
        setTranslations(JSON.stringify(data.data.data, null, 2));
      }
      if (!silent) setLoading(false);
    } catch (e) {
      if (!silent) setLoading(false);
      console.warn(e);
    }
  };

  useEffect(() => {
    getTranslations();
  }, []);

  const onSave = async (silent = false) => {
    if (!silent) setButtonLoading(true);
    try {
      const data = await axios.put(baseUrl + "translations", {
        translation: JSON.parse(translations),
      });

      if (data.data.success) {
        getTranslations();
        alert("Translations Updated successfully");
      } else {
        alert(data.data?.message || "Something went wrong!");
      }
      if (!silent) setButtonLoading(false);
    } catch (e) {
      if (!silent) setButtonLoading(false);
      alert(e?.response?.data?.message || e?.message || "Something went wrong");
      console.warn(e);
    }
  };

  return (
    <div style={{ height: "calc(100vh - 56px)", width: "100%", padding: 30 }}>
      {
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <TextField
            label={"Translations"}
            variant="filled"
            multiline
            minRows={10}
            value={translations}
            onChange={(e) => setTranslations(e.target.value)}
          />

          <div style={{ height: 20 }} />

          {buttonLoading ? (
            <div className="loader"></div>
          ) : (
            <Button onClick={() => onSave()} variant="contained">
              {localizationStrings.Send}
            </Button>
          )}
        </Box>
      }
    </div>
  );
}
